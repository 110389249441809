export const PaymentPages = [
    // if will be used in future https://gitlab.asqq.io/mycoach/mycoach-front/-/merge_requests/1473
    {
        path: 'PaymentLandingPages/WebRedirect',
        route: 'web-redirect',
    },
    {
        path: 'PaymentLandingPages/PaymentDaCheckoutBravo11A',
        route: 'payment-Da-checkout-Bravo11-a',
    },
    {
        path: 'PaymentLandingPages/PaymentDaCheckoutBravo11B',
        route: 'payment-Da-checkout-Bravo11-b',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot3',
        route: 'payment-g1-foxtrot3',
    },
    {
        path: 'PaymentLandingPages/OfferA1Foxtrot3',
        route: 'offer-a1-foxtrot3',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot4',
        route: 'payment-g1-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot5',
        route: 'payment-g1-foxtrot5',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot5FullConf',
        route: 'payment-g1-foxtrot5-full-conf',
    },
    {
        path: 'PaymentLandingPages/PaymentG4Foxtrot16',
        route: 'payment-g4-foxtrot16',
    },
    {
        path: 'PaymentLandingPages/PaymentK1Foxtrot16',
        route: 'payment-k1-foxtrot16',
    },
    {
        path: 'PaymentLandingPages/PaymentG4Golf3',
        route: 'payment-g4-golf3',
    },
    {
        path: 'PaymentLandingPages/PaymentG4Golf1',
        route: 'payment-g4-golf1',
    },
    {
        path: 'PaymentLandingPages/OfferA1Foxtrot4',
        route: 'offer-a1-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/OfferA1Foxtrot5',
        route: 'offer-a1-foxtrot5',
    },
    {
        path: 'PaymentLandingPages/OfferA4Foxtrot16',
        route: 'offer-a4-foxtrot16',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot10',
        route: 'payment-g1-foxtrot10',
    },
    {
        path: 'PaymentLandingPages/OfferA1Foxtrot10',
        route: 'offer-a1-foxtrot10',
    },
    {
        path: 'PaymentLandingPages/OfferA4Golf3',
        route: 'offer-a4-golf3',
    },
    {
        path: 'PaymentLandingPages/OfferA4Golf1',
        route: 'offer-a4-golf1',
    },
    {
        path: 'PaymentLandingPages/OfferK1Foxtrot16',
        route: 'offer-k1-foxtrot16',
    },
];
