/* eslint-disable max-lines */
import { AnalyticUtils } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { useTranslation } from 'react-i18next';
import { KeyboardEvent } from 'react';

import { USER_REQUIRED_FIELDS } from 'constants/values';

import sentry from 'services/Sentry/SentryInstance';

import { IGetUserResponse } from 'types/user/getUser';
import { CurrentProduct } from 'types/payments/payments';
import { ValueWithMeasuringSystem } from 'types/onboarding/onboarding';

import { isEmptyValue, isObject, isString } from './root/utils';
import Convert from './MeasurementConverter/Convert';
import lazyWithRetry from './lazyWithRetry';

const WIDTH_SIZE = 2000;
const HEIGHT_SIZE = 2000;

export const checkFbBots = () => {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;

    return windowWidth === WIDTH_SIZE && windowHeight === HEIGHT_SIZE;
};

const convertUnits = new Convert();

export const getKgWeight = (data: ValueWithMeasuringSystem) => {
    const { unit, value } = data;

    return unit === 'lbs' ? convertUnits.fromLbsToKg(value as number) : value;
};

export const cleanObject = (object: any) => {
    for (const propName in object) {
        if (object[propName] === null || object[propName] === undefined) {
            delete object[propName];
        }
    }

    return object;
};

export const joinClasses = (...arg: (string | boolean)[]) => [...arg].join(' ');

export const replaceUnderscore = (value: string) => value?.replace(/_/g, '-');
export const replaceUnderscoreToSpace = (value: string) => value?.replace(/_/g, ' ');
export const replaceToUnderscore = (value: string) => value?.replace(/-/g, '_');
export const deleteSlash = (value: string) => value?.replace(/\//g, '');
export const snakeCaseToSpace = (value: string) => value?.split('_')?.join(' ');
export const camelCaseToSnakeCase = (value: string) =>
    value?.replace(/[A-Z]/g, (letter) => `_${letter?.toLowerCase()}`);
export const getArrayFromString = (value: string) => value?.split(',').map((a) => a?.trim());
export const snakeCaseToCamelCase = (value: string) =>
    value?.replace(/(_[a-z])/g, (group) => group?.toUpperCase()?.replace('_', ''));

export const getMinContainerHeight = () => {
    return window.innerHeight - 80 + 'px';
};

export const fromPennyToInt = (penny: number) => penny / 100;

export const getParamFromUrl = (paramName: string) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};

const KEYS_TO_REMOVE = [
    'currentBranchName',
    'token',
    'loginStatus',
    'paidStatus',
    'config',
    'completedOBs',
    'paymentType',
    'page-is-already-reload',
    'persist:root',
    'release_date',
    'uuid',
    'testania_name',
    'pusherTransportTLS',
    'testaniaResponseStatus',
];

export const deleteConfig = () => {
    KEYS_TO_REMOVE.forEach((key) => localStorage.removeItem(key));

    localStorage.setItem('isResetStore', 'true');
};

export const getDiscount = (oldPrice?: number, newPrice?: number) => {
    if (oldPrice && newPrice) {
        return (((oldPrice - newPrice) / oldPrice) * 100).toFixed();
    }

    return null;
};

export const getMaxElemFromArr = (arr: any, fieldName: string) => {
    return arr.reduce((prev: any, cur: any) => (prev[fieldName] > cur[fieldName] ? prev : cur));
};

export const getMinElemFromArr = (arr: any, fieldName: string) => {
    return arr.reduce((prev: any, cur: any) => (prev[fieldName] < cur[fieldName] ? prev : cur));
};

export const getPriceFromCents = (price: string | number) => {
    const priceValue = Number(price);

    return getToFixedNumber(priceValue / 100);
};

export const getDynamicallyDiscount = ({
    products,
    selectedPeriod,
    currentPrice,
}: {
    products: CurrentProduct[];
    selectedPeriod: number;
    currentPrice: number;
}) => {
    const productsByPeriod = products.filter((product) => product.trial === selectedPeriod);
    const maxElemFromArr = getMaxElemFromArr(productsByPeriod, 'price');
    const introductoryPriceMax = maxElemFromArr?.price;

    return Math.round((1 - currentPrice / introductoryPriceMax) * 100);
};

export const getDiscountFromFirstPayment = (
    currentProductPeriod?: CurrentProduct['period'],
    products: CurrentProduct[] = []
) => {
    const productsByPeriodFromFirstPayment = products.filter((product) => product?.period === currentProductPeriod);

    if (productsByPeriodFromFirstPayment?.length < 2) {
        return null;
    }

    const minPricedProduct = getMinElemFromArr(productsByPeriodFromFirstPayment, 'price');
    const maxPricedProduct = getMaxElemFromArr(productsByPeriodFromFirstPayment, 'price');

    return getDiscount(maxPricedProduct.price, minPricedProduct.price);
};

export const ageToDate = (age: number) => {
    const today = new Date();

    return `${today.getFullYear() - Number(age)}-01-01 00:00:00`;
};

export const getToFixedNumber = (value: number, numbersAfterComma = 2) => {
    const numberValue = Number(value);

    return Number(numberValue.toFixed(numbersAfterComma));
};

// format of input date 'yyyy-01-01 00:00:00'
export const getAgeFromBirthday = (birthday: string) => {
    if (birthday) {
        const today = new Date();

        const todayYear = today.getFullYear();
        const yearOfBirth = birthday.split('-')[0];

        return todayYear - Number(yearOfBirth);
    } else return null;
};

// TODO: rewrite function to pass variables
export const translate = (key: string) => {
    const { t } = useTranslation();

    return t(key);
};

export const enableOnlyDigitsInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
        return;
    }
    if (event.key < '0' || event.key > '9') {
        event.preventDefault();
    }
};

export const setOnlyPositiveNumber = (digit: number) => {
    return digit <= 0 ? 1 : digit;
};

export const generateQueryParams = () => {
    const urlParams = localStorage.getItem('urlParams');
    const defaultUrlParams = AnalyticUtils.getDefaultUrlParams();

    const params = urlParams ? JSON.parse(urlParams) : defaultUrlParams;

    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

    return searchParams.toString();
};

const fromEntriesPolyfill = (iterable: IterableIterator<any>) => {
    return [...iterable].reduce((obj, [key, val]) => {
        obj[key] = val;

        return obj;
    }, {});
};

export const getUrlParams = () => {
    const queryParams = window.location.search;

    if (!isEmptyValue(queryParams)) {
        const urlSearchParams = new URLSearchParams(queryParams)?.entries();

        if (typeof Object.fromEntries === 'function') {
            return Object.fromEntries(urlSearchParams);
        }

        return fromEntriesPolyfill(urlSearchParams);
    }

    return null;
};

export const getWindowSize = (params = '(min-width: 1024px)') => {
    const mediaQuery = window.matchMedia(params);

    return mediaQuery.matches;
};

export const toCapitalize = (value: unknown, separator: string) => {
    if (typeof value === 'string' && value.length) {
        return value
            ?.split(separator)
            ?.map((item: string) => item.charAt(0).toUpperCase() + item.slice(1))
            ?.join(' ');
    }
};

export const getLanguage = () => localStorage.getItem('language') || 'en';

// TODO: rename to getIsMale
export const getGender = (gender: string) => gender === 'male';

export const checkRequiredFields = (userData: IGetUserResponse) => {
    const missingFields: string[] = [];

    USER_REQUIRED_FIELDS.forEach((item: keyof IGetUserResponse) => {
        if (!userData[item]) missingFields.push(item);
    });

    if (missingFields.length) {
        sentry.logError(new Error('Missing required fields'), SENTRY_APP, ERROR_LEVELS.ERROR, {
            missing_fields: missingFields,
        });
    }
};

export function lazyWithPreload(factory: () => Promise<any>) {
    const Component: any = lazyWithRetry(factory);

    Component.preload = factory;

    return Component;
}

// fix of local problem with SSRF
export const replaceDataWithLocalhost = (data: any) => {
    Object.entries(data).map(([fieldName, fieldValue]) => {
        if (isString(fieldValue)) {
            if ((fieldValue as string).includes('localhost')) {
                data[fieldName] = 'test';
            }
        }

        if (isObject(fieldValue)) {
            replaceDataWithLocalhost(fieldValue);
        }
    });
};
