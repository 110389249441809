export const SET_PAYMENT_CONFIG = 'SET_PAYMENT_CONFIG';
export const CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE';
export const VALIDATE_PAYMENT = 'VALIDATE_PAYMENT';
export const VALIDATE_PAYMENT_SUCCESS = 'VALIDATE_PAYMENT_SUCCESS';
export const VALIDATE_PAYMENT_FAIL = 'VALIDATE_PAYMENT_FAIL';
export const INIT_SOLID_PAYMENT = 'INIT_SOLID_PAYMENT';
export const SET_PAYPAL_LINK = 'SET_PAYPAL_LINK';
export const INIT_PAY_PAL = 'INIT_PAY_PAL';
export const INIT_PAYMENT_GENERATOR = 'INIT_PAYMENT_GENERATOR';
export const SET_MERCHANT_DATA = 'SET_MERCHANT_DATA';
export const SET_SHOW_APPLE_PAY_METHOD = 'SET_SHOW_APPLE_PAY_METHOD';
export const SET_SHOW_GOOGLE_PAY_METHOD = 'SET_SHOW_GOOGLE_PAY_METHOD';
export const VALIDATE_PAYMENT_DATA = 'VALIDATE_PAYMENT_DATA';
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
export const SET_CURRENT_PRODUCT_CODE = 'SET_CURRENT_PRODUCT_CODE';
export const SET_MAIN_PRODUCT = 'SET_MAIN_PRODUCT';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_SUBSCRIPTION_ID = 'SET_SUBSCRIPTION_ID';
export const SET_PAYMENT_CURRENCY = 'SET_PAYMENT_CURRENCY';
export const SET_EXCLUSIVE_OFFER = 'SET_EXCLUSIVE_OFFER';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const INIT_ONE_CLICK_PAYMENT = 'INIT_ONE_CLICK_PAYMENT';
export const SET_UPSELL_PRODUCT_PRICE = 'SET_UPSELL_PRODUCT_PRICE';
export const SET_UPSELL_PRODUCT_ID = 'SET_UPSELL_PRODUCT_ID';
export const SET_PURCHASE_WITHOUT_VALUE = 'SET_PURCHASE_WITHOUT_VALUE';
export const SET_REPLACE_SUBSCRIPTION = 'SET_REPLACE_SUBSCRIPTION';
export const SET_SHORT_FLOW = 'SET_SHORT_FLOW';
export const SET_PAYMENT_DISCOUNT = 'SET_PAYMENT_DISCOUNT';
export const SET_IS_PAYMENT_SCREEN = 'SET_IS_PAYMENT_SCREEN';
export const SET_PAYMENT_FAIL_INFO = 'SET_PAYMENT_FAIL_INFO';

export const SET_CUSTOM_PAYMENT_BUTTON = 'SET_CUSTOM_PAYMENT_BUTTON';
export const SET_HIDE_CUSTOM_PAYMENT_BUTTON = 'SET_HIDE_CUSTOM_PAYMENT_BUTTON';
export const SET_IS_APPLE_PAY_AVAILABLE = 'SET_IS_APPLE_PAY_AVAILABLE';
export const SET_IS_GOOGLE_PAY_AVAILABLE = 'SET_IS_GOOGLE_PAY_AVAILABLE';

export const SET_ALL_PRODUCTS_FROM_FIRST_PAYMENT = 'SET_ALL_PRODUCTS_FROM_FIRST_PAYMENT';

export const SET_CURRENT_PLAN_TAB = 'SET_CURRENT_PLAN_TAB';
