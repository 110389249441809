/* eslint-disable max-lines */
export const OnboardingPages = [
    {
        path: 'OnboardingPages/Goal',
        route: 'dem-motive-goal',
    },
    {
        path: 'OnboardingPages/GoalConf',
        route: 'dem-motive-goal-conf',
    },
    {
        path: 'OnboardingPages/CurrentBody',
        route: 'active-flev-act-body',
    },
    {
        path: 'OnboardingPages/CurrentBodyExtended',
        route: 'current-body-type',
    },
    {
        path: 'OnboardingPages/LastPerfectWeight',
        route: 'behave-hist-perfectW8',
    },
    {
        path: 'OnboardingPages/FeelBreak',
        route: 'nutr-habbit-feel',
    },
    {
        path: 'OnboardingPages/SleepDuration',
        route: 'active-habit-slip',
    },
    {
        path: 'OnboardingPages/WaterConsumption',
        route: 'nutr-habbit-water',
    },
    {
        path: 'OnboardingPages/Diet',
        route: 'diets',
    },
    {
        path: 'OnboardingPages/PsychoPower',
        route: 'behave-psych-lazy',
    },
    {
        path: 'OnboardingPages/PsychoBusy',
        route: 'behave-psych-busy',
    },
    {
        path: 'OnboardingPages/PreferenceEat',
        route: 'nutr-routine-preferences',
    },
    {
        path: 'OnboardingPages/TargetBody',
        route: 'active-flev-targ-body',
    },
    {
        path: 'OnboardingPages/TargetBodyExtended',
        route: 'target-body-type',
    },
    {
        path: 'OnboardingPages/TargetZones',
        route: 'active-flev-targ-z',
    },
    {
        path: 'OnboardingPages/ActivityLevel',
        route: 'ob-active-level',
    },
    {
        path: 'OnboardingPages/ObCurrentWeight',
        route: 'ob-weight-current',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightA',
        route: 'ob-weight-current-a',
    },
    {
        path: 'OnboardingPages/ObTargetWeight',
        route: 'ob-weight-target',
    },
    {
        path: 'OnboardingPages/ObTargetWeightA',
        route: 'ob-weight-target-a',
    },
    {
        path: 'OnboardingPages/ObTargetWeightB',
        route: 'ob-weight-target-b',
    },
    {
        path: 'OnboardingPages/ObFastingAwareness',
        route: 'ob-fasting-awareness-a',
    },
    {
        path: 'OnboardingPages/BreakfastTime',
        route: 'ob-fasting-time-breakfast',
    },
    {
        path: 'OnboardingPages/LunchTime',
        route: 'ob-fasting-time-lunch',
    },
    {
        path: 'OnboardingPages/DinnerTime',
        route: 'ob-fasting-time-dinner',
    },
    {
        path: 'OnboardingPages/PreferredPlace',
        route: 'ob-fasting-pref-eat-place',
    },
    {
        path: 'OnboardingPages/FastingWeekend',
        route: 'ob-fasting-weekend',
    },
    {
        path: 'OnboardingPages/WorkoutSchedule',
        route: 'ob-fasting-workout',
    },
    {
        path: 'OnboardingPages/JobActive',
        route: 'ob-fasting-job-active',
    },
    {
        path: 'OnboardingPages/FastingMotivation',
        route: 'ob-fasting-motivation',
    },
    {
        path: 'OnboardingPages/ObSocialProof',
        route: 'ob-social-proof',
    },
    {
        path: 'OnboardingPages/ObSocialProofOver',
        route: 'ob-social-proof-over',
    },
    {
        path: 'OnboardingPages/ObSocialProofAges',
        route: 'ob-social-proof-ages',
    },
    {
        path: 'OnboardingPages/ObSocialProofFullConf',
        route: 'social-proof-full-conf',
    },
    {
        path: 'OnboardingPages/ObPushupsSquats',
        route: 'ob-pushups-squats',
    },
    {
        path: 'OnboardingPages/ObFitnessWalking',
        route: 'ob-fitness-walking',
    },
    {
        path: 'OnboardingPages/WorkloadType',
        route: 'ob-fasting-job-load',
    },
    {
        path: 'OnboardingPages/ObGoal',
        route: 'ob-goal',
    },
    {
        path: 'OnboardingPages/ObRegularActivity',
        route: 'ob-regular-activity',
    },
    {
        path: 'OnboardingPages/ObLastTime',
        route: 'ob-last-time',
    },
    {
        path: 'OnboardingPages/ObBadHabits',
        route: 'ob-bad-habits',
    },
    {
        path: 'OnboardingPages/ObPhysicalActivity',
        route: 'ob-physical-activity',
    },
    {
        path: 'OnboardingPages/ObCapacity',
        route: 'ob-capacity',
    },
    {
        path: 'OnboardingPages/ObHeight',
        route: 'ob-height',
    },
    {
        path: 'OnboardingPages/ObHeightA',
        route: 'ob-height-a',
    },
    {
        path: 'OnboardingPages/ObAge',
        route: 'ob-age',
    },
    {
        path: 'OnboardingPages/ObAgeA',
        route: 'ob-age-a',
    },
    {
        path: 'OnboardingPages/DescribeYou',
        route: 'ob-walking-habits',
    },
    {
        path: 'OnboardingPages/ObBodyType',
        route: 'ob-bodytype',
    },
    {
        path: 'OnboardingPages/ObMealplanVeg',
        route: 'ob-mealplan-vegetables',
    },
    {
        path: 'OnboardingPages/ObMealplanProt',
        route: 'ob-mealplan-protein',
    },
    {
        path: 'OnboardingPages/ObRest',
        route: 'ob-rest',
    },
    {
        path: 'OnboardingPages/ObHydration',
        route: 'ob-hydration',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion',
    },
    {
        path: 'OnboardingPages/ObOccasionResult',
        route: 'ob-occasion-result',
    },
    {
        path: 'OnboardingPages/InterestsA',
        route: 'interests-a',
    },
    {
        path: 'OnboardingPages/ObGoalsOver',
        route: 'ob-goals-over',
    },
    {
        path: 'OnboardingPages/ActiveFlevTargZOver',
        route: 'active-flev-targ-z-over',
    },
    {
        path: 'OnboardingPages/BehaveHistPerfectW8Over',
        route: 'behave-hist-perfectW8-over',
    },
    {
        path: 'OnboardingPages/ObStairsOver',
        route: 'ob-stairs-over',
    },
    {
        path: 'OnboardingPages/ObBackIssue',
        route: 'ob-back-issue',
    },
    {
        path: 'OnboardingPages/Creating',
        route: 'creating',
    },
    {
        path: 'OnboardingPages/CreatingB',
        route: 'creating-b',
    },
    {
        path: 'OnboardingPages/EmailAccountE',
        route: 'email-account-e',
    },
    {
        path: 'OnboardingPages/EmailAccountF',
        route: 'email-account-f',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/EmailAConf',
        route: 'email-a-conf',
    },
    {
        path: 'OnboardingPages/EmailAConfA',
        route: 'email-a-conf-a',
    },
    {
        path: 'OnboardingPages/EmailD',
        route: 'email-d',
    },
    {
        path: 'OnboardingPages/EmailE',
        route: 'email-e',
    },
    {
        path: 'OnboardingPages/ObNumberMeals',
        route: 'ob-number-meals',
    },
    {
        path: 'OnboardingPages/ObStomachDiscomfort',
        route: 'ob-stomach-discomfort',
    },
    {
        path: 'OnboardingPages/ObName',
        route: 'ob-name',
    },
    {
        path: 'OnboardingPages/BehaveChoice',
        route: 'behave-choice-sc',
    },
    {
        path: 'OnboardingPages/BehaveEating',
        route: 'behave-eating-sc',
    },
    {
        path: 'OnboardingPages/BehaveImpulse',
        route: 'behave-impulse-sc',
    },
    {
        path: 'OnboardingPages/BehaveMultitasking',
        route: 'behave-multitasking-sc',
    },
    {
        path: 'OnboardingPages/BehaveOldhabits',
        route: 'behave-oldhabits-sc',
    },
    {
        path: 'OnboardingPages/BehavePsychBusy',
        route: 'behave-psych-busy-sc',
    },
    {
        path: 'OnboardingPages/BehavePsychLazy',
        route: 'behave-psych-lazy-sc',
    },
    {
        path: 'OnboardingPages/BehaveRoutines',
        route: 'behave-routines-sc',
    },
    {
        path: 'OnboardingPages/ObTags',
        route: 'ob-tags',
    },
    {
        path: 'OnboardingPages/ObTagsA',
        route: 'ob-tags-a',
    },
    {
        path: 'OnboardingPages/ObFeedbackAge',
        route: 'ob-feedback-age',
    },
    {
        path: 'OnboardingPages/ObPregnant',
        route: 'ob-pregnant',
    },
    {
        path: 'OnboardingPages/ObMedications',
        route: 'ob-medications',
    },
    {
        path: 'OnboardingPages/ObBodyShape',
        route: 'ob-body-shape',
    },
    {
        path: 'OnboardingPages/ObFitnessLevel',
        route: 'ob-fitness-level',
    },
    {
        path: 'OnboardingPages/ObConfig',
        route: 'ob-config-1',
    },
    {
        path: 'OnboardingPages/ObConfig',
        route: 'ob-config-2',
    },
    {
        path: 'OnboardingPages/ObConfig',
        route: 'ob-config-3',
    },
    {
        path: 'OnboardingPages/ObUnfortunately',
        route: 'ob-unfortunately',
    },
    {
        path: 'OnboardingPages/ObWeightRange',
        route: 'ob-weight-range',
    },
    {
        path: 'OnboardingPages/ObRestrictiveDiet',
        route: 'ob-restrictive-diet',
    },
    {
        path: 'OnboardingPages/ObTargetZones/ObTargetZonesB',
        route: 'ob-target-zones-b',
    },
    {
        path: 'OnboardingPages/ObTargetZones/ObTargetZonesC',
        route: 'ob-target-zones-c',
    },
    {
        path: 'OnboardingPages/ObTargetZones/ObTargetZonesD',
        route: 'ob-target-zones-d',
    },
    {
        path: 'OnboardingPages/ObFamiliarityFull',
        route: 'ob-familiarity-full',
    },
    {
        path: 'GeneralPages/EmailConsent',
        route: 'email-consent',
    },
    {
        path: 'GeneralPages/EmailConsentA',
        route: 'email-consent-a',
    },
    {
        path: 'GeneralPages/EmailConsentAConf',
        route: 'email-consent-a-conf',
    },
    {
        path: 'OnboardingPages/ObRestrictiveYoYo',
        route: 'ob-restrictive-yoyo',
    },
    {
        path: 'OnboardingPages/ObRestrictiveYoYo',
        route: 'ob-restrictive-yoyo-conf',
    },
    {
        path: 'OnboardingPages/ObGender',
        route: 'ob-gender',
    },
    {
        path: 'OnboardingPages/CreatingDataComp',
        route: 'creating-data-comp',
    },
    {
        path: 'OnboardingPages/ObDataComp',
        route: 'ob-data-comp',
    },
    {
        path: 'OnboardingPages/PolicyConsent',
        route: 'policy-consent',
    },
    {
        path: 'OnboardingPages/StepTracker',
        route: 'step-tracker',
    },
    {
        path: 'OnboardingPages/TrainingDays',
        route: 'training-days',
    },
    {
        path: 'OnboardingPages/Equipment',
        route: 'equipment',
    },
    {
        path: 'OnboardingPages/GoalDynamic',
        route: 'goal',
    },
    {
        path: 'OnboardingPages/WorkoutTypes',
        route: 'workout-types',
    },
    {
        path: 'OnboardingPages/GoalMult',
        route: 'goal-mult',
    },
];
