import { createSelector } from 'reselect';

import { PaymentStore } from 'types/store/paymentStore';

import { RootState } from '../rootReducer';

const selectPayment = (state: RootState) => <PaymentStore>state.payment;

const selectApplePay = createSelector(selectPayment, (payment) => payment.applePay);
const selectCheckoutOrderId = createSelector(selectPayment, (payment) => payment.checkoutOrderId);
const selectCurrency = createSelector(selectPayment, (payment) => payment.currency);
const selectCurrentProduct = createSelector(selectPayment, (payment) => payment.currentProduct);
const selectMainProduct = createSelector(selectPayment, (payment) => payment.mainProduct);
const selectCurrentProductCode = createSelector(selectPayment, (payment) => payment.currentProductCode);
const selectExclusiveOfferStatus = createSelector(selectPayment, (payment) => payment.exclusiveOfferStatus);
const selectInitPaymentData = createSelector(selectPayment, (payment) => payment.initPaymentData);
const selectIsShowApplePayMethod = createSelector(selectPayment, (payment) => payment.isShowApplePayMethod);
const selectIsShowGooglePayMethod = createSelector(selectPayment, (payment) => payment.isShowGooglePayMethod);
const selectIsLoading = createSelector(selectPayment, (payment) => payment.isLoading);
const selectIsShortFlow = createSelector(selectPayment, (payment) => payment.isShortFlow);
const selectPayPalLink = createSelector(selectPayment, (payment) => payment.payPalLink);
const selectPaymentConfigPaypal = createSelector(selectPayment, (payment) => payment.paymentConfig.paypal);
const selectPaymentConfigApplePay = createSelector(selectPayment, (payment) => payment.paymentConfig.applaPay);
const selectPaymentMethod = createSelector(selectPayment, (payment) => payment.paymentMethod);
const selectPaymentType = createSelector(selectPayment, (payment) => payment.paymentType);
const selectPaymentValidate = createSelector(selectPayment, (payment) => payment.paymentValidate);
const selectPurchaseWithoutValue = createSelector(selectPayment, (payment) => payment.purchaseWithoutValue);
const selectSolidInited = createSelector(selectPayment, (payment) => payment.solidInited);
const selectSubscriptionId = createSelector(selectPayment, (payment) => payment.subscriptionId);
const selectUpsellId = createSelector(selectPayment, (payment) => payment.upsellId);
const selectValidatePaymentData = createSelector(selectPayment, (payment) => payment.validatePaymentData);
const selectOneClickPaymentPrice = createSelector(selectPayment, (payment) => payment.oneClickPaymentPrice);
const selectPaymentDiscount = createSelector(selectPayment, (payment) => payment.paymentDiscount);
const selectIsPaymentScreen = createSelector(selectPayment, (payment) => payment.isPaymentScreen);
const selectIsCustomPaymentButton = createSelector(selectPayment, (payment) => payment.isCustomPaymentButton);
const selectIsHideCustomPaymentButton = createSelector(selectPayment, (payment) => payment.isHideCustomPaymentButton);
const selectMerchantData = createSelector(selectPayment, (payment) => payment.merchantData);
const selectAllProductsFromFirstPayment = createSelector(
    selectPayment,
    (payment) => payment.allProductsFromFirstPayment
);
const selectIsApplePayAvailable = createSelector(selectPayment, (payment) => payment.isApplePayAvailable);
const selectIsGooglePayAvailable = createSelector(selectPayment, (payment) => payment.isGooglePayAvailable);
const selectCurrentPlanTab = createSelector(selectPayment, (payment) => payment.currentPlanTab);

export {
    selectPayment,
    selectApplePay,
    selectCheckoutOrderId,
    selectCurrency,
    selectCurrentProduct,
    selectCurrentProductCode,
    selectMainProduct,
    selectExclusiveOfferStatus,
    selectInitPaymentData,
    selectIsLoading,
    selectIsShortFlow,
    selectPayPalLink,
    selectPaymentConfigPaypal,
    selectPaymentConfigApplePay,
    selectPaymentMethod,
    selectPaymentType,
    selectPaymentValidate,
    selectPurchaseWithoutValue,
    selectSolidInited,
    selectSubscriptionId,
    selectUpsellId,
    selectValidatePaymentData,
    selectOneClickPaymentPrice,
    selectPaymentDiscount,
    selectIsPaymentScreen,
    selectIsCustomPaymentButton,
    selectIsHideCustomPaymentButton,
    selectIsShowApplePayMethod,
    selectIsShowGooglePayMethod,
    selectMerchantData,
    selectAllProductsFromFirstPayment,
    selectIsApplePayAvailable,
    selectIsGooglePayAvailable,
    selectCurrentPlanTab,
};
