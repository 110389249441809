import Solid from 'solid-payment';
import { ERROR_LEVELS, SENTRY_AXIOS, SENTRY_AXIOS_URGENT } from 'sentry-utils';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import api from 'apiSingleton';
import { getUserSignature, getFrontHashSignature } from 'api/constants/urls';

import { selectIsPaid } from 'redux/User/selectors';

import { APP_FULL_ACCESS, PAYMENT_PURCHASE_ERROR } from 'constants/payments';

import sentry from 'services/Sentry/SentryInstance';

import { deleteConfig } from 'helpers/utils';

import PurchaseErrorModal from 'modals/PurchaseErrorModal';
import GeneralErrorModal from 'modals/GeneralErrorModal';

import { IOnErrorData } from '../types/api';

const ErrorHandler = () => {
    const [isOpenGeneralErrorModal, setIsOpenGeneralErrorModal] = useState(false);
    const [isOpenPurchaseErrorModal, setIsOpenPurchaseErrorModal] = useState(false);

    const [productCode, setProductCode] = useState(APP_FULL_ACCESS);

    const isPaidUser = useSelector(selectIsPaid);

    const handleErrorStatus = ({ error, tmpOptions }: IOnErrorData) => {
        const responseConfig = error?.response?.config;
        const responseURL = error?.response?.config?.url;
        const responseData = error?.response?.data;
        const status = error?.response?.status;

        const isGetUserBySignatureMethod = responseURL?.startsWith(`/${getUserSignature}`);
        const isGetFrontHashSignatureMethod = responseURL?.startsWith(`/${getFrontHashSignature}`);
        const isPaymentError = responseURL?.startsWith('/payments');

        const isExclusionEndpoint = isGetFrontHashSignatureMethod || isGetUserBySignatureMethod;

        if (status === 403 && !isExclusionEndpoint) {
            deleteConfig();

            if (location.search) history.pushState(null, '', location.origin);

            location.reload();
        }

        if (status === 409 && responseData?.message === PAYMENT_PURCHASE_ERROR) {
            const requestData = JSON.parse(responseConfig?.data);

            sentry.logError(new Error('PURCHASE ERROR MODAL IS OPEN'), SENTRY_AXIOS, ERROR_LEVELS.ERROR, {
                productCode: requestData?.product_code,
                ...tmpOptions,
                ...error?.response
            });

            requestData?.product_code && setProductCode(requestData?.product_code);
            setIsOpenPurchaseErrorModal(true);
        }

        if (status === 409 && isPaymentError && !isPaidUser) {
            sentry.logError(error, SENTRY_AXIOS_URGENT, ERROR_LEVELS.CRITICAL, { ...tmpOptions, ...error?.response });
        }

        if (status === 500 || status === 404) {
            setIsOpenGeneralErrorModal(true);
        }

        if (status >= 500) {
            sentry.logError(error, SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, { ...tmpOptions, ...error?.response });
        }
    };

    useEffect(() => {
        api.onError(({ error, tmpOptions }: IOnErrorData) => {
            handleErrorStatus({ error, tmpOptions });
        });

        Solid.onError(({ error, tmpOptions }: IOnErrorData) => {
            handleErrorStatus({ error, tmpOptions });
        });
    }, []);

    return (
        <>
            {isOpenGeneralErrorModal && <GeneralErrorModal isOpenModal closeHandler={() => setIsOpenGeneralErrorModal(false)} />}
            {isOpenPurchaseErrorModal && (
                <PurchaseErrorModal
                    isOpen
                    productCode={productCode}
                    closeHandler={() => setIsOpenPurchaseErrorModal(false)}
                />
            )}
        </>
    );
};

export default ErrorHandler;
