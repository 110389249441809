export const StartPages = [
    {
        path: 'StartPages/HomeAGenderFb',
        route: 'home-a-gender-fb',
    },
    {
        path: 'StartPages/Index',
        route: 'index',
    },
    {
        path: 'StartPages/StartAgeOver',
        route: 'start-age-over',
    },
    {
        path: 'StartPages/StartLanding',
        route: 'start-landing',
    },
    {
        path: 'StartPages/StartAgePresA',
        route: 'start-age-pres-a',
    },
    {
        path: 'StartPages/StartAgeFull',
        route: 'start-age-full',
    },
    {
        path: 'StartPages/StartAgeFullConf',
        route: 'start-age-full-conf',
    },
    {
        path: 'StartPages/StartAgeFullConfA',
        route: 'start-age-conf-full-a',
    },
    {
        path: 'StartPages/StartRange',
        route: 'start-range',
    },
];
