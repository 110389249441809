import { InitConfig } from '@solidgate/client-sdk-loader';

import { ValidatePayload } from 'types/payments/validate';
import { ReplaceSubscriptionPayload } from 'types/payments/replaceSubscription';
import { Init } from 'types/payments/paymentSystemsInit';
import { Currency, CurrentProduct, MainProduct, Dispatch, PaymentMethod, ProductCode } from 'types/payments/payments';
import { IPaymentFailInfo } from 'types/payments/paymentApiInterfaces';
import { IInitOneClickPayment } from 'types/payments/oneClickPayment';

import * as actionTypes from './actionTypes';

/* ================= NEW PAYMENT ACTIONS*/

export const setCurrentProduct = (product: CurrentProduct) => {
    return {
        type: actionTypes.SET_CURRENT_PRODUCT,
        payload: product,
    };
};

export const setCurrentProductCode = (code: ProductCode) => {
    return {
        type: actionTypes.SET_CURRENT_PRODUCT_CODE,
        payload: code,
    };
};

export const setMainProduct = (product: MainProduct) => {
    return {
        type: actionTypes.SET_MAIN_PRODUCT,
        payload: product,
    };
};

export const initPayPal = (data: Init) => {
    return {
        type: actionTypes.INIT_PAY_PAL,
        payload: data,
    };
};

export const initPaymentGenerator = (data: Init) => {
    return {
        type: actionTypes.INIT_PAYMENT_GENERATOR,
        payload: data,
    };
};

export const setMerchantData = (data: InitConfig['merchantData']) => {
    return {
        type: actionTypes.SET_MERCHANT_DATA,
        payload: data,
    };
};

export const setIsShowApplePayMethod = (status: boolean) => {
    return {
        type: actionTypes.SET_SHOW_APPLE_PAY_METHOD,
        payload: status,
    };
};

export const setIsShowGooglePayMethod = (status: boolean) => {
    return {
        type: actionTypes.SET_SHOW_GOOGLE_PAY_METHOD,
        payload: status,
    };
};

export const setLoadingStatus = (status: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATUS,
        payload: status,
    };
};

export const changeExclusiveOfferState = (status: boolean) => {
    return {
        type: actionTypes.SET_EXCLUSIVE_OFFER,
        payload: status,
    };
};

export const validatePayment = (payload: ValidatePayload) => {
    return {
        type: actionTypes.VALIDATE_PAYMENT,
        payload: payload,
    } as const;
};

/* =================*/

export const changePaymentMethod = (type: PaymentMethod | null) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CHANGE_PAYMENT_TYPE,
            payload: type,
        });
    };
};

export const setPaymentConfig = (payload: { [payment: string]: boolean }) => {
    return {
        type: actionTypes.SET_PAYMENT_CONFIG,
        payload: payload,
    };
};

export const setCurrency = (currency: Currency) => {
    return {
        type: actionTypes.SET_PAYMENT_CURRENCY,
        payload: currency,
    };
};

export const setUpsellProductPrice = (price: number) => {
    return {
        type: actionTypes.SET_UPSELL_PRODUCT_PRICE,
        payload: price,
    };
};

export const setUpsellProductId = (upsellId: string) => {
    return {
        type: actionTypes.SET_UPSELL_PRODUCT_ID,
        payload: upsellId,
    };
};

export const initOneClickPayment = (data: IInitOneClickPayment) => {
    return {
        type: actionTypes.INIT_ONE_CLICK_PAYMENT,
        payload: data,
    };
};

export const setPurchaseWithoutValue = (status: boolean) => {
    return {
        type: actionTypes.SET_PURCHASE_WITHOUT_VALUE,
        payload: status,
    };
};

export const replaceSubscription = (data: ReplaceSubscriptionPayload) => {
    return {
        type: actionTypes.SET_REPLACE_SUBSCRIPTION,
        payload: data,
    };
};

export const setShortFlow = (data: boolean) => {
    return {
        type: actionTypes.SET_SHORT_FLOW,
        payload: data,
    };
};

export const setPaymentDiscount = (discount: number) => {
    return {
        type: actionTypes.SET_PAYMENT_DISCOUNT,
        payload: discount,
    };
};

export const setAllProductsFromFirstPayment = (status: CurrentProduct[]) => {
    return {
        type: actionTypes.SET_ALL_PRODUCTS_FROM_FIRST_PAYMENT,
        payload: status,
    };
};

export const setIsPaymentScreen = (isPayment: boolean) => {
    return {
        type: actionTypes.SET_IS_PAYMENT_SCREEN,
        payload: isPayment,
    };
};

export const setCustomPaymentButton = (status: boolean) => {
    return {
        type: actionTypes.SET_CUSTOM_PAYMENT_BUTTON,
        payload: status,
    };
};

export const setHideCustomPaymentButton = (status: boolean) => {
    return {
        type: actionTypes.SET_HIDE_CUSTOM_PAYMENT_BUTTON,
        payload: status,
    };
};

export const setIsApplePayAvailable = (isApplePayAvailable: boolean) => {
    return {
        type: actionTypes.SET_IS_APPLE_PAY_AVAILABLE,
        payload: isApplePayAvailable,
    };
};

export const setIsGooglePayAvailable = (isGooglePayAvailable: boolean) => {
    return {
        type: actionTypes.SET_IS_GOOGLE_PAY_AVAILABLE,
        payload: isGooglePayAvailable,
    };
};

export const setPaymentFailInfo = (paymentFailInfo: IPaymentFailInfo) => {
    return {
        type: actionTypes.SET_PAYMENT_FAIL_INFO,
        payload: paymentFailInfo,
    } as const;
};

export const setCurrentPlanTab = (tab: string) => {
    return {
        type: actionTypes.SET_CURRENT_PLAN_TAB,
        payload: tab,
    };
};
